<template>
  <div class="wysiwyg">
    <ol class="list-decimal ml-5">
      <li>
        點選「新增展品」，可直接進入展品頁面挑選展品，選好之後按確認鍵，即可新增至下方展品欄位。
      </li>
      <li>
        點選「新增文字」，可直接進入文字頁面編輯，編輯好之後按確認鍵，即可新增至下方展品欄位。
      </li>
      <li>
        點選「新增媒體」，可直接進入圖片頁面上傳圖片或影片，傳好之後按確認鍵，即可新增至下方展品欄位。
      </li>
    </ol>
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
.link {
  @apply text-sm text-gray-400;
}
</style>
